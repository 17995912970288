import * as React from 'react'

import { Button } from '@thg-commerce/gravity-elements'
import { Close } from '@thg-commerce/gravity-icons/src'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

interface RecentlySearchedProps {
  recentlySearched:
    | {
        itemName: string
        timeStamp: Date
      }[]
    | null
  recentlySearchedTitle?: string
  recentlySearchedText?: string
  onClick?: () => void
  clearRecentSearch?: string
  movePastInput?: () => void
  removeIndividualSearch?: (value) => void
}

const RecentItemsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  padding: ${spacing(3)} ${spacing(2)} ${spacing(2)};
  margin-top: 1px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(3)} ${spacing(2)};
    margin-top: 0;
  }
`

const RecentSearchLink = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.linkFont.entry,
      props.theme.patterns.header.search.linkFont.style,
    )};
  ${(props) => `
  text-decoration: ${
    props.theme.patterns.header.search.linkFont.textDecoration || 'underline'
  };
  text-transform: ${
    props.theme.patterns.header.search.linkFont.transform || 'none'
  };
  color: ${props.theme.patterns.header.search.linkFont.textColor || 'inherit'};
  `}
  display: flex;
  gap: ${spacing(1)};
  width: 100%;
  align-items: center;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }
`

const RecentlyItemsTitle = styled.p`
  ${Text('bodyText', 'alternate')};
`

const StyledText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
`

const StyledButton = styled(Button)`
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }
`

export const RecentSearches = (props: RecentlySearchedProps) => {
  return (
    <RecentItemsContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: spacing(3),
          alignItems: 'center',
        }}
      >
        <RecentlyItemsTitle>{props.recentlySearchedTitle}</RecentlyItemsTitle>
        <StyledButton
          sizing="regular"
          emphasis="low"
          renderedAs="a"
          href=""
          onClick={(e) => {
            e.stopPropagation()
            props.onClick && props.onClick()
          }}
        >
          {props.clearRecentSearch}
        </StyledButton>
      </div>

      <div
        style={{ display: 'flex', gap: spacing(1), flexDirection: 'column' }}
      >
        {props.recentlySearched &&
          props.recentlySearched.map((item, index) => {
            if (item.itemName) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  key={index}
                >
                  <RecentSearchLink
                    key={index}
                    href={`/elysium.search?search=${item.itemName}`}
                  >
                    <StyledText>{item.itemName}</StyledText>
                  </RecentSearchLink>
                  <StyledButton
                    sizing="regular"
                    emphasis="low"
                    renderedAs="a"
                    href=""
                    onClick={() =>
                      props.removeIndividualSearch &&
                      props.removeIndividualSearch(item.itemName)
                    }
                    onKeyDown={
                      props.recentlySearched &&
                      index === props.recentlySearched.length - 1
                        ? (e: React.KeyboardEvent) => {
                            if (e.key === 'Tab' && !e.shiftKey) {
                              props.movePastInput && props.movePastInput()
                            }
                          }
                        : undefined
                    }
                  >
                    <Close />
                  </StyledButton>
                </div>
              )
            }
            return null
          })}
      </div>
    </RecentItemsContainer>
  )
}
