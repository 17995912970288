import * as React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { isIOSSafari } from '@thg-commerce/gravity-system'
import { mq, spacing, zIndex } from '@thg-commerce/gravity-theme'

import { SearchI18nText } from '../../HeaderSearchForm'
import { HeaderZIndexLevel } from '../../types'

import { ProductSearchResults } from './ProductSearchResults'
import { SearchCorrections } from './SearchCorrections'
import { SearchSuggestions } from './SearchSuggestions'
import { SearchResults } from './types'

export interface SearchDropdownProps {
  clearInput: () => void
  movePastInput: () => void
  isDesktop: boolean
  searchResults: SearchResults
  searchI18nText: SearchI18nText
  autocompleteLink: string
  inputValue: string
  correctionsIndex: number
  suggestionsIndex: number
  productsIndex: number
  fromText: string
  rrpText?: string
  freeText?: string
  slimHeader: boolean
  showPowerReview: boolean
  onClickSearchSuggestions?: (value: string) => void
}

export const DropdownWrapper = styled.div<{
  paddingTop: number
  pageWidth: number
  cutBottom: boolean
  slimHeader: boolean
}>`
  width: ${(props) =>
    props.pageWidth >= 1200
      ? props.theme.patterns.header.search.width
      : `${props.pageWidth}px`};
  min-width: 336px;
  position: absolute;
  overflow-y: scroll;
  max-height: calc(100vh - ${(props) => (props.cutBottom ? '200' : '100')}px);
  padding-top: ${(props) => spacing(props.paddingTop)};
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  ${zIndex(HeaderZIndexLevel.Dropdown)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    right: ${(props) =>
      props.slimHeader && !props.theme.patterns.header.mobileSearch
        ? '0'
        : `auto`};
  }
`

export const SearchResultsDropdown = (props: SearchDropdownProps) => {
  const clientWidth = document.documentElement.clientWidth
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const selectedRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (selectedRef.current && dropdownRef.current) {
      const halfHeight = dropdownRef.current.offsetHeight / 2
      const selectedResultOffsetTop = selectedRef.current.offsetTop
      const scrollToPos =
        halfHeight > selectedResultOffsetTop
          ? 0
          : selectedResultOffsetTop - halfHeight

      dropdownRef.current.scrollTo(0, scrollToPos)
    }
  }, [props.correctionsIndex, props.suggestionsIndex, props.productsIndex])

  const correctionsResultsPresent = props.searchResults.corrections?.length > 0

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
      data-testid="header-search-results-dropdown"
    >
      <DropdownWrapper
        pageWidth={clientWidth}
        cutBottom={isIOSSafari}
        ref={dropdownRef}
        aria-label={props.searchI18nText.searchResultsAria}
        onKeyDown={(e: React.KeyboardEvent) =>
          e.key === 'Escape' && props.clearInput()
        }
        paddingTop={props.isDesktop ? 0 : 2}
        {...props}
      >
        {correctionsResultsPresent && (
          <SearchCorrections
            currentIndex={props.correctionsIndex}
            corrections={props.searchResults.corrections}
            correctionsTitle={props.searchI18nText.correctionsTitle}
            autocompleteLink={props.autocompleteLink}
            selectedRef={selectedRef}
            {...(props.onClickSearchSuggestions && {
              onClickSearchSuggestions: props.onClickSearchSuggestions,
            })}
          />
        )}
        {props.searchResults.suggestedSearchQueries?.length > 0 && (
          <SearchSuggestions
            currentIndex={props.suggestionsIndex}
            showTitle={correctionsResultsPresent}
            suggestions={props.searchResults.suggestedSearchQueries}
            suggestionsTitle={props.searchI18nText.suggestionsTitle}
            autocompleteLink={props.autocompleteLink}
            selectedRef={selectedRef}
            {...(props.onClickSearchSuggestions && {
              onClickSearchSuggestions: props.onClickSearchSuggestions,
            })}
          />
        )}
        {props.searchResults.products?.length > 0 && (
          <ProductSearchResults
            currentIndex={props.productsIndex}
            products={props.searchResults.products}
            productsTitle={props.searchI18nText.productsTitle}
            selectedRef={selectedRef}
            fromText={props.fromText}
            rrpText={props.rrpText}
            freeText={props.freeText}
            movePastInput={props.movePastInput}
            inputValue={props.inputValue}
            showPowerReview={props.showPowerReview}
          />
        )}
      </DropdownWrapper>
    </div>
  )
}
