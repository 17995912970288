import * as React from 'react'
import * as Cookies from 'js-cookie'

import { SearchResults } from '../HeaderSearch/SearchResultsDropdown'

import { TrendingTerms } from './types'

enum Directions {
  up = 'UP',
  down = 'DOWN',
}

const updateDirectionUp = (
  itemsUnselected: boolean,
  correctionsIndex: number,
  setCorrectionsIndex: (value: number) => void,
  setSuggestionsIndex: (value: number) => void,
  setProductsIndex: (value: number) => void,
  correctionsLength: number,
  suggestionsIndex: number,
  suggestedSearchQueriesLength: number,
  productsIndex: number,
) => {
  if (itemsUnselected) {
    return
  }
  if (correctionsIndex > -1) {
    setCorrectionsIndex(correctionsIndex - 1)
  } else if (suggestionsIndex > -1) {
    if (suggestionsIndex === 0) {
      setCorrectionsIndex(correctionsLength - 1)
    }
    setSuggestionsIndex(suggestionsIndex - 1)
  } else if (productsIndex > -1) {
    if (productsIndex === 0) {
      setSuggestionsIndex(suggestedSearchQueriesLength - 1)
    }
    setProductsIndex(productsIndex - 1)
  }
}

const updateDirectionDown = (
  productsIndex: number,
  productsLength: number,
  correctionsIndex: number,
  correctionsLength: number,
  setProductsIndex: (value: number) => void,
  suggestionsIndex: number,
  suggestedSearchQueriesLength: number,
  setSuggestionsIndex: (value: number) => void,
  setCorrectionsIndex: (value: number) => void,
) => {
  if (productsIndex === productsLength - 1) {
    return
  }

  if (correctionsIndex > -1) {
    if (correctionsIndex === correctionsLength - 1) {
      setSuggestionsIndex(0)
      setCorrectionsIndex(-1)
    } else {
      setProductsIndex(productsIndex + 1)
    }
  } else if (suggestionsIndex > -1) {
    if (suggestionsIndex === suggestedSearchQueriesLength - 1) {
      setProductsIndex(0)
      setSuggestionsIndex(-1)
    } else {
      setSuggestionsIndex(suggestionsIndex + 1)
    }
  } else if (productsIndex > -1) {
    setProductsIndex(productsIndex + 1)
  }
}

const updateIndexes = (
  direction: Directions,
  instantSearchResults: SearchResults | null,
  itemsUnselected: boolean,
  correctionsIndex: number,
  setCorrectionsIndex: (value: number) => void,
  productsIndex: number,
  setSuggestionsIndex: (value: number) => void,
  setProductsIndex: (value: number) => void,
  suggestionsIndex: number,
) => {
  let correctionsLength: number
  let suggestedSearchQueriesLength: number
  let productsLength: number
  if (instantSearchResults?.corrections?.length) {
    correctionsLength = instantSearchResults?.corrections?.length
  } else {
    correctionsLength = 0
  }
  if (instantSearchResults?.suggestedSearchQueries?.length) {
    suggestedSearchQueriesLength =
      instantSearchResults?.suggestedSearchQueries?.length
  } else {
    suggestedSearchQueriesLength = 0
  }
  if (instantSearchResults?.products?.length) {
    productsLength = instantSearchResults?.products?.length
  } else {
    productsLength = 0
  }

  switch (direction) {
    case Directions.up:
      updateDirectionUp(
        itemsUnselected,
        correctionsIndex,
        setCorrectionsIndex,
        setSuggestionsIndex,
        setProductsIndex,
        correctionsLength,
        suggestionsIndex,
        suggestedSearchQueriesLength,
        productsIndex,
      )
      break
    case Directions.down:
      updateDirectionDown(
        productsIndex,
        productsLength,
        correctionsIndex,
        correctionsLength,
        setProductsIndex,
        suggestionsIndex,
        suggestedSearchQueriesLength,
        setSuggestionsIndex,
        setCorrectionsIndex,
      )
      break
    default:
      break
  }
}
const arrowDown = (
  itemsUnselected: boolean,
  correctionsLength: number,
  setCorrectionsIndex: (value: number) => void,
  suggestedSearchQueriesLength: number,
  setSuggestionsIndex: (value: number) => void,
  productsLength: number,
  setProductsIndex: (value: number) => void,
) => {
  if (itemsUnselected) {
    if (correctionsLength > 0) {
      setCorrectionsIndex(0)
    } else if (suggestedSearchQueriesLength > 0) {
      setSuggestionsIndex(0)
    } else if (productsLength > 0) {
      setProductsIndex(0)
    }
  }
}

export const onKeyDown = (
  event: React.KeyboardEvent,
  instantSearchResults: SearchResults | null,
  clearInput: () => void,
  isDesktop: boolean,
  setScrollLock: (lock: boolean, $document: Document, $window: Window) => void,
  restartIndexes: () => void,
  setInputFocused: (value: boolean) => void,
  itemsUnselected: boolean,
  correctionsIndex: number,
  setCorrectionsIndex: (value: number) => void,
  productsIndex: number,
  setSuggestionsIndex: (value: number) => void,
  setProductsIndex: (value: number) => void,
  suggestionsIndex: number,
  goToLink: () => void,
) => {
  let correctionsLength: number
  let suggestedSearchQueriesLength: number
  let productsLength: number = 0

  if (instantSearchResults?.corrections?.length) {
    correctionsLength = instantSearchResults.corrections.length
  } else {
    correctionsLength = 0
  }

  if (instantSearchResults?.suggestedSearchQueries?.length) {
    suggestedSearchQueriesLength =
      instantSearchResults.suggestedSearchQueries.length
  } else {
    suggestedSearchQueriesLength = 0
  }

  if (instantSearchResults?.products?.length) {
    productsLength = instantSearchResults.products.length
  }

  switch (event.key) {
    case 'Escape':
      clearInput()
      isDesktop && setScrollLock(true, document, window)
      break
    case 'Enter':
      if (!itemsUnselected) {
        event.preventDefault()
        goToLink()
      }
      break
    case 'ArrowUp':
      if (
        correctionsIndex !== -1 ||
        suggestionsIndex !== -1 ||
        productsIndex !== -1
      ) {
        event.preventDefault()
      }
      updateIndexes(
        Directions.up,
        instantSearchResults,
        itemsUnselected,
        correctionsIndex,
        setCorrectionsIndex,
        productsIndex,
        setSuggestionsIndex,
        setProductsIndex,
        suggestionsIndex,
      )
      break
    case 'ArrowDown':
      arrowDown(
        itemsUnselected,
        correctionsLength,
        setCorrectionsIndex,
        suggestedSearchQueriesLength,
        setSuggestionsIndex,
        productsLength,
        setProductsIndex,
      )
      updateIndexes(
        Directions.down,
        instantSearchResults,
        itemsUnselected,
        correctionsIndex,
        setCorrectionsIndex,
        productsIndex,
        setSuggestionsIndex,
        setProductsIndex,
        suggestionsIndex,
      )
      break
    case 'Tab':
      if (event.shiftKey) {
        clearInput()
        setInputFocused(false)
      } else restartIndexes()
      break
  }
}

export const onKeyDownEvent = (
  event: React.KeyboardEvent,
  inputValue: string,
  clearInput: () => void,
  setInputFocused: (value: boolean) => void,
  externalClose?: () => void,
  enableRecentSearches?: boolean,
  enablePromotionalSearch?: boolean,
  trendingTerms?: TrendingTerms,
) => {
  if (
    event.key === 'Tab' &&
    inputValue === '' &&
    (enableRecentSearches || enablePromotionalSearch || trendingTerms)
  ) {
    clearInput()
    setInputFocused(true)
    externalClose && externalClose()
  }

  if (
    event.key === 'Tab' &&
    inputValue === '' &&
    (!enableRecentSearches || !enablePromotionalSearch || !trendingTerms)
  ) {
    clearInput()
    setInputFocused(false)
    externalClose && externalClose()
  }
}

export const updateRecentlySearched = (
  searchValue: string,
  category?: string,
) => {
  const values = Cookies.getJSON('recentlySearchedProducts') || []

  values.push({
    itemName: searchValue,
    itemCategory: category ?? '',
    timeStamp: new Date().getTime(),
  })
  const limitedValues = values.slice(-5)

  const uniqueArrayValues = limitedValues
    .reduce((accumulator, item) => {
      const isDuplicate = accumulator.some(
        (existingItem) => existingItem.itemName === item.itemName,
      )

      if (!isDuplicate) {
        accumulator.push(item)
      }

      return accumulator
    }, [])
    .filter((item) => item.itemName.trim() !== '')

  if (uniqueArrayValues.length !== 0) {
    Cookies.set('recentlySearchedProducts', JSON.stringify(uniqueArrayValues))
  }
}

export const removeRecentlySearched = () => {
  Cookies.remove('recentlySearchedProducts')
}
export const removeIndividualSearch = (name, setRecentSearches) => {
  const values = JSON.parse(Cookies.get('recentlySearchedProducts'))
  const filteredItems = values.filter((item) => item.itemName !== name)

  Cookies.set('recentlySearchedProducts', JSON.stringify(filteredItems))
  setRecentSearches && setRecentSearches(filteredItems)
}
