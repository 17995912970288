import * as React from 'react'

import { LocationCircle } from '@thg-commerce/gravity-icons/src'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

export interface CorrectionsProps {
  correctionsTitle: string
  corrections: InstantSearchCorrection[]
  autocompleteLink: string
  currentIndex: number
  selectedRef: any
  onClickSearchSuggestions?: (value: string) => void
}

export interface InstantSearchCorrection {
  correction: string
  highlightedSearchCorrection: string
}

const CorrectionsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  margin-top: ${spacing(1)};
  padding: ${spacing(1)} 0;
`

const CorrectionsLinkContainer = styled.li<{ isSelected: boolean }>`
  margin: 0;
  border: 2px solid transparent;
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }

  ${(props) =>
    props.isSelected
      ? `background-color: ${props.theme.colors.palette.greys.lighter};
      display: flex;
      justify-content: space-between;
    `
      : ''}
`
const CorrectionsLink = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.linkFont.entry,
      props.theme.patterns.header.search.linkFont.style,
    )};
  ${(props) => `
text-decoration: ${props.theme.patterns.header.search.linkFont.textDecoration};
text-transform: ${props.theme.patterns.header.search.linkFont.transform};
color: ${props.theme.patterns.header.search.linkFont.textColor};`}
  padding: ${spacing(1)} ${spacing(2)};

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`

const CorrectionsTitle = styled.p`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.dropdownHeaderFont.entry,
      props.theme.patterns.header.search.dropdownHeaderFont.style,
    )};
  ${(props) => `
text-decoration: ${props.theme.patterns.header.search.dropdownHeaderFont.textDecoration};
text-transform: ${props.theme.patterns.header.search.dropdownHeaderFont.transform};
color: ${props.theme.patterns.header.search.dropdownHeaderFont.textColor};`}
  padding: ${spacing(2)};
`

const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledLocationCircle = styled(LocationCircle)`
  g {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const SearchCorrections = (props: CorrectionsProps) => {
  const Corrections = () => {
    return props.corrections.map((correction, index) => {
      const isSelected = props.currentIndex === index

      return (
        <CorrectionsLinkContainer
          key={`correction ${correction.correction}`}
          isSelected={isSelected}
          ref={isSelected ? props.selectedRef : undefined}
        >
          <CorrectionsLink
            {...(props.onClickSearchSuggestions && {
              onClick: (e) => {
                e.stopPropagation()
                props.onClickSearchSuggestions &&
                  props?.onClickSearchSuggestions(correction.correction)
              },
            })}
            href={`${props.autocompleteLink}correction&search=${correction.correction}`}
          >
            {correction.correction}
          </CorrectionsLink>
          {isSelected && (
            <CircleWrapper>
              <StyledLocationCircle />
            </CircleWrapper>
          )}
        </CorrectionsLinkContainer>
      )
    })
  }

  return (
    <CorrectionsContainer>
      <CorrectionsTitle>{props.correctionsTitle}</CorrectionsTitle>
      <ul aria-label={props.correctionsTitle}>{Corrections()}</ul>
    </CorrectionsContainer>
  )
}
