import * as React from 'react'

import { LocationCircle } from '@thg-commerce/gravity-icons/src'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

export interface SearchSuggestionsProps {
  suggestionsTitle: string
  suggestions: string[]
  autocompleteLink: string
  showTitle: boolean
  currentIndex: number
  selectedRef: any
  onClickSearchSuggestions?: (value: string) => void
}

const SuggestionsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  margin-top: ${spacing(1)};
  padding: ${spacing(1)} 0;
`

const SuggestionsLinkContainer = styled.li<{ isSelected: boolean }>`
  margin: 0;
  border: 2px solid transparent;
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }

  ${(props) =>
    props.isSelected
      ? `background-color: ${props.theme.colors.palette.greys.lighter};
      display: flex;
      justify-content: space-between;
    `
      : ''}
`

const SuggestionsLink = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.linkFont.entry,
      props.theme.patterns.header.search.linkFont.style,
    )};
  ${(props) => `
    text-decoration: ${props.theme.patterns.header.search.linkFont.textDecoration};
    text-transform: ${props.theme.patterns.header.search.linkFont.transform};
    color: ${props.theme.patterns.header.search.linkFont.textColor};`}
  display: block;
  padding: ${spacing(1)} ${spacing(2)};

  &:focus {
    outline: none;
  }
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`
const CircleWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SuggestionsTitle = styled.p`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.dropdownHeaderFont.entry,
      props.theme.patterns.header.search.dropdownHeaderFont.style,
    )};
  ${(props) => `
text-decoration: ${props.theme.patterns.header.search.dropdownHeaderFont.textDecoration};
text-transform: ${props.theme.patterns.header.search.dropdownHeaderFont.transform};
color: ${props.theme.patterns.header.search.dropdownHeaderFont.textColor};`}
  padding: ${spacing(2)};
`
const StyledLocationCircle = styled(LocationCircle)`
  g {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const SearchSuggestions = (props: SearchSuggestionsProps) => {
  const Suggestions = () => {
    return props.suggestions.map((suggestion, index) => {
      const isSelected = props.currentIndex === index

      return (
        <SuggestionsLinkContainer
          key={`suggestion ${suggestion}`}
          isSelected={isSelected}
          ref={isSelected ? props.selectedRef : undefined}
        >
          <SuggestionsLink
            {...(props.onClickSearchSuggestions && {
              onClick: (e) => {
                e.stopPropagation()
                props.onClickSearchSuggestions &&
                  props.onClickSearchSuggestions(suggestion)
              },
            })}
            href={`${props.autocompleteLink}searchsuggestion&search=${suggestion}`}
          >
            {suggestion}
          </SuggestionsLink>
          {isSelected && (
            <CircleWrapper>
              <StyledLocationCircle />
            </CircleWrapper>
          )}
        </SuggestionsLinkContainer>
      )
    })
  }

  return (
    <SuggestionsContainer>
      {props.showTitle && (
        <SuggestionsTitle>{props.suggestionsTitle}</SuggestionsTitle>
      )}
      <ul aria-label={props.suggestionsTitle}>{Suggestions()}</ul>
    </SuggestionsContainer>
  )
}
